export { default as HighHeelsImage } from './high_heels.png';
export { default as ChevronCircleIcon } from './icons/chevron-circle.svg';
export { default as ChevronIcon } from './icons/chevron.svg';
export { default as CloseIcon } from './icons/close.svg';
export { default as HomeIcon } from './icons/home.svg';
export { default as SuccessIcon } from './icons/success.svg';

export { default as BusinessMeetingImage } from './business_meeting.png';
export { default as KidsSeminarImage } from './kids-seminar.png';
export { default as IconLogo } from './logo/icon.svg';
export { default as WordmarkLogo } from './logo/wordmark.svg';
